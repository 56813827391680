import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import moment from "moment";
import swal from "@sweetalert/with-react";
import styled from "styled-components";

//* ==> Config <== *//
import { TIME_SESION } from "@config/env";

//* ==> UseDataApi <== *//
import useDataApi from "@hooks/useDataApi";

//* ==> Paths <== *//
import { pathExtendSession } from "@config/paths";

//* ==> Context <== *//
import useSessionContext from "@contexts/useSessionContext"

//* ==> Components <== *//
import Loading from "@components/Loading";

//* ==> Helpers <== *//
import CustomAlert from "@helpers/CustomAlert";
import { postMessage } from "@helpers/sw";

// * ==> Hooks
import useLogout from "@hooks/useLogout";

let intervalMainId = null;
let intervalID = null;

/**
 * @typedef ExtendSessionProps
 * @property {boolean} isLogged
 * @property {(flag: boolean) => void} setLogout
 */

/**
 * @author Francisco Hernández
 * @description Component to be able to extend the User's active session.
 * @param {ExtendSessionProps} props
 * @returns
 */
const ExtendSession = () => {
  const { t } = useTranslation();

  const { logout } = useLogout();

  // ==> Contexts
  const { state: store, dispatch } = useSessionContext();
  const {
    isLogged,
    session: { id: idSssionStore, logoutTime: logoutTimeStore, rToken: rTokenStore, changePass: changePassStore },
    user: { code: codUserStore }
  } = store;

  let WAIT_TIME = 30; // 10 segundos
  let SHOW_TIME = 2; // 2 minutos
  let AD_WAIT_TIME = 2; // 2 segundos

  const [counter, setCounter] = useState(WAIT_TIME);
  const [showScreen, setShowScreen] = useState(false);

  const [stateExtendSession, fetchExtendSession] = useDataApi({
    url: pathExtendSession,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  const btnExtendSession = () => {
    autoClear();
    fetchExtendSession({
      method: "post",
      fetchHeaders: {
        refreshToken: rTokenStore ?? "",
        codUsuario: encodeURIComponent(codUserStore ?? ""),
        idSesion: idSssionStore ?? "",
      },
    });
  };

  useEffect(() => {
    // Si esta logueado, y hay un intervalo activo
    if (isLogged && (intervalMainId || intervalID)) {
      if (moment().isAfter(moment(logoutTimeStore))) btnCerrarSesion();
    }
  }, [store]);

  useEffect(() => {
    // Si esta logueado, y no hay un intervalo activo
    if (isLogged && !intervalMainId && !intervalID) {
      if (!changePassStore) startMainTimer();
    }

    // Si no esta logueado, y hay un intervalo activo
    if (!isLogged && intervalMainId && intervalID) {
      btnCerrarSesion();
    }

    return () => autoClear();
  }, [isLogged]);

  const startMainTimer = (logoutTime) => {
    // Obtener valores
    const end = moment(logoutTime ?? logoutTimeStore).format("YYYY-MM-DD HH:mm:ss");
    const before = moment(end).subtract(SHOW_TIME, "minutes");
    const beforeWait = moment(end).subtract(WAIT_TIME, "seconds");

    intervalMainId = setInterval(() => {
      const now = moment().format("YYYY-MM-DD HH:mm:ss");

      // Si esta por expirar
      if (moment(now).isAfter(before) && moment(now).isBefore(end)) {

        // Si faltan menos del tiempo de espera para que expire
        if (moment(now).isAfter(beforeWait)) {
          // Se acabo el tiempo, se cerrará sesión automaticamente
          btnCerrarSesion();
        } else {
          startTimer(end);
          stopMainTimer();
        }
      }

      // Si el tiempo ha expirado
      if (moment(now).isAfter(end)) {
        // Se acabo el tiempo, se cerrará sesión automaticamente
        btnCerrarSesion();
      }
    }, 1000);
  }

  const stopMainTimer = () => {
    clearInterval(intervalMainId);
    intervalMainId = null;
  }

  const startTimer = (logoutTime) => {
    // Obtener valores
    const end = moment(logoutTime ?? logoutTimeStore).format("YYYY-MM-DD HH:mm:ss");
    const beforeWait = moment(end).subtract(WAIT_TIME, "seconds");

    let count = WAIT_TIME + AD_WAIT_TIME;
    setCounter(WAIT_TIME);
    setShowScreen(true); // Mostrar pantalla de extender sesión
    postMessage({ action: "extendSession", data: { msg: t("extenderSession-msg") } })

    intervalID = setInterval(() => {
      if (moment().isAfter(moment(beforeWait))) btnCerrarSesion();
      else {
        count--;

        if (count === 0) {
          // Se acabo el tiempo, se cerrará sesión automaticamente
          btnCerrarSesion();
        }
        setCounter(count - AD_WAIT_TIME <= 0 ? 0 : count - AD_WAIT_TIME);
      }
    }, 1000);
  }

  const stopTimer = () => {
    clearInterval(intervalID);
    intervalID = null;
  };

  const logoutFcn = () => {
    logout();
  };

  const clearTimers = () => {
    stopTimer();
    stopMainTimer();
  };

  const autoClear = () => {
    clearTimers();
    setCounter(null);
    setShowScreen(false);
  };

  useEffect(() => {
    if (stateExtendSession.isError) {
      swal({
        content: <CustomAlert text={t("extenderSession-error")} type="error" />,
      });
      btnCerrarSesion(); // Se cerrará sesión automaticamente
      return;
    }

    if (
      !stateExtendSession.isLoading &&
      stateExtendSession.isSuccess &&
      stateExtendSession.data
    ) {
      const data = stateExtendSession?.data ?? {};
      const code = data?.codigo ?? 1;
      const descrip = data?.descripcion ?? "";

      if (code !== 0) {
        const msj = t("extenderSession-error");
        swal({
          content: <CustomAlert text={descrip ?? msj} isTrad={!Boolean(descrip)} />,
        });
        btnCerrarSesion(); // Se cerrará sesión automaticamente
        return;
      }

      //# Datos a extraer

      // Datos de la sesión
      const newToken = data?.accessToken ?? "";
      const newRToken = data?.refreshToken ?? "";
      const newTimeExpired = data?.tiempoExpiracion ?? (TIME_SESION * 60);

      if (
        !(newToken && newToken !== "") ||
        !(newRToken && newRToken !== "") ||
        !(newTimeExpired && newTimeExpired !== "")
      ) {
        const msj = t("extenderSession-error");
        swal({
          content: <CustomAlert text={descrip ?? msj} isTrad={!Boolean(descrip)} />,
        });
        btnCerrarSesion(); // Se cerrará sesión automaticamente
        return;
      }

      // Tiempo de sesión
      const now = moment().format("YYYY-MM-DD HH:mm:ss");
      const newLogoutTime = moment(now).add(newTimeExpired, "seconds").format("YYYY-MM-DD HH:mm:ss");

      dispatch({
        type: "UPDATE_SESION",
        payload: {
          session: {
            token: newToken,
            rToken: newRToken,
            logoutTime: newLogoutTime,
            time: newTimeExpired,
          },
        },
      });

      startMainTimer(newLogoutTime);
      stopTimer();
    }

  }, [stateExtendSession]);

  const btnCerrarSesion = () => {
    autoClear();
    logoutFcn();
  };

  return (
    <>
      {stateExtendSession.isLoading ? (
        <Loading />
      ) : (
        <>
          {showScreen && (
            <Wrapper>
              <div class="circle-wrap">
                <div class="circle">
                  <div class="mask full">
                    <div class="fill"></div>
                  </div>
                  <div class="mask half">
                    <div class="fill"></div>
                  </div>
                  <div class="inside-circle"> {counter} </div>
                </div>
              </div>

              <span>{t("extenderSession-info")}</span>
              <div className="containerBtns">
                <button
                  type="button"
                  className="btn-Secundary"
                  onClick={() => btnCerrarSesion()}
                >
                  {t("cerrarSession-title")}
                </button>
                <button type="button" onClick={() => btnExtendSession()}>
                  <Trans i18nKey="extenderSessionBtn-label" />
                </button>
              </div>
            </Wrapper>
          )}
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 12;
  height: 100vh;
  width: 100%;
  background: var(--color-primary);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;

  span {
    color: var(--color-blue);
    font-weight: var(--bold-weight);
    font-size: 16px;
    text-align: justify;
  }

  .circle-wrap {
    margin-bottom: 30px;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 1px solid #cdcbd0;
  }

  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 75px;
    height: 75px;
    position: absolute;
    border-radius: 50%;
  }

  .circle-wrap .circle .mask {
    clip: rect(0px, 75px, 75px, 38px);
  }

  .circle-wrap .inside-circle {
    width: 61px;
    height: 61px;
    border-radius: 50%;
    background: #d2eaf1;
    line-height: 60px;
    text-align: center;
    margin-top: 7px;
    margin-left: 7px;
    color: #1e51dc;
    position: absolute;
    font-weight: var(--bold-weight);
    font-size: 2em;
  }

  /* color animation */

  /* 3rd progress bar */
  .mask .fill {
    clip: rect(0px, 38px, 75px, 0px);
    background-color: #227ded;
  }

  .mask.full,
  .circle .fill {
    animation: fill ease-in-out infinite 1s;
    transform: rotate(0deg);
  }

  @keyframes fill {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  .containerBtns {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    width: 75%;

    @media (max-width: 700px) {
      flex-direction: column-reverse;
      height: 100px;
    }
  }
`;

export default ExtendSession;
