// ==> IP'S

//** ==> OFBOX IP's <== **//
// const URL = "http://10.37.10.5:8182";

//** ==> OFBOX IP's 'AEGON' (New) <== **//
// const URL = "http://192.168.17.56:9000";

//** ==> BFA IP's - Desarrollo <== **//
//const URL = "http://172.16.7.135:9000";

//** ==> BFA IP's - Pre Producción <== **//
//const URL = "https://ppbmovil.bfa.gob.sv";

//** ==> BFA IP's - Producción <== **//
const URL = "https://bmovil.bfa.gob.sv";

//* ==> Consulta de Cuentas <== *//
// ==> Cuentas de Ahorro
export const pathConsultaCuentasAhorro = `${URL}/cxf/bxi/consultas/cuentasahorros`;
export const pathDetalleCuentaAhorro = pathConsultaCuentasAhorro;
export const pathMovimientosCuentaAhorro = `${pathConsultaCuentasAhorro}/movimientos`;
// ==> Cuentas de Ahorro
export const pathConsultaCuentasCorriente = `${URL}/cxf/bxi/consultas/cuentascorrientes`;

export const pathDetalleCuentaCorriente = pathConsultaCuentasCorriente;
export const pathMovimientosCuentaCorriente = `${pathConsultaCuentasCorriente}/movimientos`;
// ==> Consulta de Cuentas Propias - Abono
export const pathCuentasPropiasAbono = `${URL}/cxf/bxi/consultas/cuentaspropias/abonos`;

//* ==> Endpoint de información General <== *//
// ==> Menú
export const pathGetMenu = (codUser, codCanal = "BXI") =>
  `${URL}/cxf/bxi/consultas/menu/${codUser}/${codCanal}`;
// ==> Información del Usuario
export const pathConsultaInformacionUsuario = `${URL}/cxf/bxi/consultas/informacion/usuario/solicitud`;
// Información del Cliente Logueado
export const datosCliente = `${URL}/cxf/bxi/consultas/datosDeCliente`;
// ==> Agencias
export const pathConsultaAgencias = `${URL}/cxf/bxi/consultas/agencias`;
// ==> Agencias relacionadas al Cliente
export const pathConsultaAgenciasCliente = `${URL}/cxf/bxi/consultas/oficinas/agencia/cliente`;
// ==> Parentesco/Vinculo
export const pathParentescoVinculo = `${URL}/cxf/bxi/consultas/parentescoVinculo`;
// ==> Paises
export const pathPaisDestino = `${URL}/cxf/bxi/consultas/paisDestino`;
// ==> Paises SIPA
export const pathPaisDestinoSIPA = `${URL}/cxf/bxi/consultas/paisDestinoSipa`;
// ==> Estado Destino
export const pathEstadoDestino = `${URL}/cxf/bxi/consultas/estadoDestino`;
// ==> Ciudad Destino
export const pathCiudadDestino = `${URL}/cxf/bxi/consultas/ciudadDestino`;
// ==> Tipos de Crédito
export const pathObtenerTiposCredito = `${URL}/cxf/bxi/consultas/tipoCredito`;
// ==> Líneas de Crédito
export const pathObtenerLineasDeCredito = `${URL}/cxf/bxi/consultas/lineaCredito`;
// ==> Tipos de Seguros
export const pathTipoSeguro = `${URL}/cxf/bxi/consultas/tipoSeguro`;
// ==> Planes de Seguros
export const pathPlanesSeguro = `${URL}/cxf/bxi/consultas/planesSeguro`;
// ==> Descargar Comprobante
export const pathDescargarArchivo = `${URL}/cxf/bxi/soporte/comprobantes`;
// Signatures
export const getRequiredSignatures = (codUsuario, codCanal) =>
  `${URL}/cxf/bxi/consultas/autorizadores/formula/${codUsuario}/${codCanal}`;
export const getSignersAvailable = (codUsuario, codCanal) =>
  `${URL}/cxf/bxi/consultas/autorizadores/${codUsuario}/${codCanal}`;
// ==> Origen de Fondos
export const pathOrigenFondos = `${URL}/cxf/bxi/consultas/origenFondos`;

//* ==> Endpoint de Consultas <== *//
// ==> Consulta de Préstamos
export const pathConsultaPrestamos = `${URL}/cxf/bxi/consultas/prestamos`;
export const pathDetallePrestamo = pathConsultaPrestamos;
export const pathDetallePrestamoTercero = `${URL}/cxf/bxi/consultas/prestamos/terceros`;
export const pathTipoPagoPrestamo = `${URL}/cxf/bxi/consultas`;
// ==> Pago de Préstamos
export const pathPagoPrestamo = `${URL}/cxf/bxi/pagos/prestamo/`;

// ==> Consulta Deposito
export const pathConsultaDepositosPlazo = `${URL}/cxf/bxi/consultas/depositos/clientes`;
export const pathDetalleDeposito = pathConsultaDepositosPlazo;

// ==> Suspensión de Cheques
export const pathSuspensionCheques = `${URL}/cxf/bxi/solicitudes/suspensiones/cheques`;

// ==> Reporte Tarjeta Debito por Viaje
export const pathTarjetaDebitoViaje = `${URL}/cxf/bxi/reporte/tarjetadebitoviaje`;

// ==> Solicitud de Captación
export const pathConsultaTiposCtas = `${URL}/cxf/bxi/consultas/preSolCaptacion`;
export const pathSolicitudCaptacion = `${URL}/cxf/bxi/solicitud/captaciones`;

// ==> Obtener Prestamos de Seguro
export const pathObtenerPrestamosSeguro = `${URL}/cxf/bxi/obtener/prestamoSeguroCliente`;
export const pathObtenerPrestamosSeguroInformacion = pathObtenerPrestamosSeguro;

// ==> Solicitud de Credito
export const pathSolicitudCredito = `${URL}/cxf/bxi/solicitud/credito/`;
export const pathDestiSolicitudCredito = `${URL}/cxf/bxi/consultas/preSolCredito`;

// ==> Solicitud de Cheques Internacionales
export const pathSolicitudGirosInternacionales = `${URL}/cxf/bxi/solicitud/girosInternacionales`;

// ==> FSV
export const pathFSVConsultas = `${URL}/cxf/bxi/pagos/servicios/FSV/consultas`;
export const pathFSVPago = `${URL}/cxf/bxi/pagos/servicios/FSV`;
export const pathValidMontoFSV = `${URL}/cxf/bxi/consultas/limiteDatoFsv`;

// ==> Cuota Estimada
export const pathCuotaEstimada = `${URL}/cxf/bxi/consultas/cuotaEstimada`;

// ==> Solicitud de Seguro
export const pathEnvioSolicitudSeguro = `${URL}/cxf/bxi/solicitud/seguro`;

// ==> Ministerio de Hacienda
export const pathPagoMinisterioHacienda = `${URL}/cxf/bxi/pagos/servicios/MH`;

// ==> AFP-Confia
export const pathPagoAfpConfia = `${URL}/cxf/bxi/pagos/servicios/AFP/Confia`;
export const pathConsultaNpeAfpConfia = `${URL}/cxf/bxi/pagos/servicios/AFP/Confia/consulta`;

// ==> AFP-Crecer
export const pathPagoAfpCrecer = `${URL}/cxf/bxi/pagos/servicios/AFP/Crecer`;
export const pathConsultaNpeAfpCrecer = `${URL}/cxf/bxi/pagos/servicios/AFP/Crecer/consulta`;

// ==> Cheques de Gerencia
export const pathSolicitudChequesGerencia = `${URL}/cxf/bxi/cheques/gerencia`;

// Solicitud de Chequera
export const pathTipoChequera = `${URL}/cxf/bxi/consultas/tiposChequera`;
export const pathSolicitudChequeras = `${URL}/cxf/bxi/solicitudes/chequeras`;

// CNR
export const consultarCNR = `${URL}/cxf/bxi/pagos/CNR/consultas`;
export const pagoCNR = `${URL}/cxf/bxi/pagos/CNR`;

// AES
export const consultasAES = `${URL}/cxf/bxi/pagos/servicios/AES/consultas`;
export const pagoAES = `${URL}/cxf/bxi/pagos/servicios/AES/`;

// DELSUR
export const consultasDELSUR = `${URL}/cxf/bxi/pagos/servicios/DELSUR/consultas`;
export const pagoDELSUR = `${URL}/cxf/bxi/pagos/servicios/DELSUR/`;

// ISSS
export const consultasISSS = `${URL}/cxf/bxi/pagos/ISSS/consultas`;
export const pagoISSS = `${URL}/cxf/bxi/pagos/ISSS`;

// CLARO
export const consultaClaro = `${URL}/cxf/bxi/pagos/claro/consultas`;
export const pagosClaro = `${URL}/cxf/bxi/pagos/claro`;

// Temporal PATHS
export const pathGenerateToken = `${URL}/cxf/generar/token/BXI`;

// Solicitudes de Transferencias internacional
export const pathTranferenciasInternaciones = `${URL}/cxf/bxi/transferencias/internacionales`;
export const pathCodigosSwift = `${URL}/cxf/bxi/consultas/codigosSwift`;
export const pathPaisesDestinoInternacional = `${URL}/cxf/bxi/consultas/paisDestinoInternacional`;
export const pathCalcularComision = `${URL}/cxf/bxi/consultas/comisionInternacional`;

// Transferencias cuentas
export const pathTransferenciasCtas = `${URL}/cxf/bxi/transferencias/cuentas`;

// Obtener cuentas de terceros
export const pathCuentasTercerosAbono = `${URL}/cxf/bxi/consultas/cuentasterceros`;

// Planilla -> Pago/Reversa
export const pathPagoPlanilla = `${URL}/cxf/bxi/pago/planillas`;
export const pathReversaPlanilla = `${pathPagoPlanilla}/reversa`;

// Aprobaciones pendientes
export const pathAprobacionesPendientes = `${URL}/cxf/bxi/consultas/transacciones`;

// Apobar transaccion pendiente
export const approveTransaction = `${URL}/cxf/bxi/aprobacion/transaccion/`;

// Pago CIEX
export const pagoCIEX = `${URL}/cxf/bxi/pagos/servicios/CIEX`;

// Banco Destino
export const bancosDestino = `${URL}/cxf/bxi/consultas/bancosDestino`;

// Banco Destino
export const detalleTransferencia = `${URL}/cxf/bxi/consultas/detallesTransferencia`;

// Transferencias LBTR
export const transferenciasLbtrLocales = `${URL}/cxf/bxi/transferencias/lbtr/locales`;
export const transferenciasLbtrRegionales = `${URL}/cxf/bxi/transferencias/lbtr/regionales`;
export const bancoDestinolbtr = `${URL}/cxf/bxi/consultas/bcoDestinoLbtr`;
export const monedaDestinoLbtr = `${URL}/cxf/bxi/consultas/monedaDestinoLbtr`;
export const insFinancieraLbtr = `${URL}/cxf/bxi/consultas/insFinancieraLbtr`;
export const productoDestinoLbtr = `${URL}/cxf/bxi/consultas/productoDestinoLbtr`;
export const horariosAtencionLbtr = `${URL}/cxf/bxi/consultas/obtenerHorario`;
export const validarHorariosLbtr = `${URL}/cxf/bxi/consultas/validarHorariosLbtr`;
export const obtainComisionLbtr = `${URL}/cxf/bxi/consultas/valorComisionSipa`;
export const obtainCatalogoLbtr = `${URL}/cxf/bxi/consultas/tipoPagoSipa`;

/*
URLS NECESARIAS PARA FORMULARIO DE TRANSFER365 CA-RD
-Kevin Santos
*/
export const bancoDestinolbtrT365Card = `${URL}/cxf/bxi/t365card/consultas/bcoDestinoLbtr`;
//export const pathPaisDestinoT365Card = `${URL}/cxf/bxi/t365card/consultas/paisDestino`;
export const pathPaisDestinoT365Card = `${URL}/cxf/bxi/t365card/consultas/paisDestinoSipa`;

//export const pathPaisDestinoSIPAT365Card = (codUsuario) => `${URL}/cxf/bxi/t365card/consultas/paisDestinoSipa/${codUsuario}`; //leva el codigo de usuario
export const pathEstadoDestinoT365Card = `${URL}/cxf/bxi/t365card/consultas/estadoDestino`;
export const pathCiudadDestinoT365Card = `${URL}/cxf/bxi/t365card/consultas/ciudadDestino`;
export const obtainCatalogoLbtrT365Card = `${URL}/cxf/bxi/t365card/consultas/tipoPagoSipa`;
export const productoDestinoLbtrT365Card = `${URL}/cxf/bxi/t365card/consultas/productoDestinoLbtr`;
export const transferenciasLbtrRegionalesT365Card = `${URL}/cxf/bxi/t365card/transferencias/lbtr/regionales`;
export const insFinancieraLbtrT365Card = `${URL}/cxf/bxi/t365card/consultas/insFinancieraLbtr`;

// Calcular Comision
export const calcularComision = (codCliente, cuentaOrigen) =>
  `${URL}/cxf/bxi/consultas/valorComision/${codCliente}/${cuentaOrigen}`;

// Calcular IVA
export const calcularIva = (valor) =>
  `${URL}/cxf/bxi/consultas/valorIva/${valor}`;

// Obtener comprobante de
export const obtenerComprobanteOrigenFondos = (codCanal, codServicio, monto) =>
  `${URL}/cxf/bxi/consultas/obtenerComprobante/${codCanal}/${codServicio}/${monto}`;

// Administración de Usuarios Anexos
export const listadoUsuariosAnexo = (codUsuario, clave) =>
  `${URL}/cxf/bxi/operaciones/usuario/listarUsuario/${codUsuario}/${clave}`;

// Acciones para usuarios
export const accionesUsuariosAnexos = (
  action,
  codUser,
  codCanal,
  codServ,
  idUser
) =>
  `${URL}/cxf/bxi/operaciones/usuario/${action}/${codUser}/${codCanal}/${codServ}/${idUser}`;
// Listar cuentas
export const listadoCuentasAsociadas = `${URL}/cxf/bxi/operaciones/usuario/listarCuentasAsociadas`;

// Listar servicios
export const listadoServicios = (codUser, codCanal = "BXI") =>
  `${URL}/cxf/bxi/operaciones/usuario/listarServicios/${codUser}/${codCanal}`;
// Crear Usuario Anexo
export const crearUsuarioAnexo = `${URL}/cxf/bxi/operaciones/usuario/crearUsuarioAnexo`;
// Restablecer contraseña
export const reestablecerPassword = (codUser, codCanal, codServ, idUser) =>
  `${URL}/cxf/bxi/operaciones/usuario/contrasenia/reestablecer/${codUser}/${codCanal}/${codServ}/${idUser}`;
// Modificar Usuario Anexo
export const modificarUsuarioAnexo = `${URL}/cxf/bxi/operaciones/usuario/modificarUsuarioAnexo`;
// Bitacora de Usuario anexo
export const bitacoraUsuarioAnexo = (codUser, fechaInicio, fechaFin) =>
  `${URL}/cxf/bxi/operaciones/usuario/bitacora/${codUser}/${fechaInicio}/${fechaFin}`;
// Detalle de bitacora de usuario anexo
export const detalleBitacoraUsuarioAnexo = `${URL}/cxf/bxi/operaciones/usuario/bitacora/detalle`;

// ==> Favoritos
export const listadoFavoritos = (codUser, status) =>
  `${URL}/cxf/bxi/favoritos/usuario/listarMisFavoritos/${status}/${codUser}`;
export const crearFavorito = `${URL}/cxf/bxi/favoritos/usuario/crearFavorito`;
export const cambiarStatusFavorito = (idFav, codUser, status) =>
  `${URL}/cxf/bxi/favoritos/usuario/cambiarStatusFavorito/${idFav}/${codUser}/${status}`;
export const detalleFavorito = (idFav, codUser) =>
  `${URL}/cxf/bxi/favoritos/usuario/verFavorito/${idFav}/${codUser}`;

// Detalle de Aprobación
export const obtenerInfoAprobacion = (codUser, idTrans) =>
  `${URL}/cxf/bxi/aprobacion/transaccion/${codUser}/${idTrans}/BXI`;

// ==> Menu colectores
export const pathMenuColectores = (codUser, codCanal = "BXI") =>
  `${URL}/cxf/bxi/consultas/colectores/${codUser}/${codCanal}`;
export const pathColectorFormato = `${URL}/cxf/bxi/consultas/colectoresGenericos/`;
export const pathPagoColectorGenerico = `${URL}/cxf/bxi/pagos/servicios/genericos`;

// ==> Solicitud Terceros
export const pathSolicitudesTercero = (codUser) =>
  `${URL}/cxf/bxi/administracion/cuentasterceros/solicitudes/consultas/${codUser}/BXI`;
export const pathCtasTerceros = (codUser) =>
  `${URL}/cxf/bxi/consultas/cuentasterceros/${codUser}/BXI`;
export const pathInicioTercero = `${URL}/cxf/bxi/administracion/cuentasterceros/solicitudes/inicio`;
export const pathIngresoTercero = `${URL}/cxf/bxi/administracion/cuentasterceros/solicitudes/ingreso`;
export const pathValidacionSolicitud = `${URL}/cxf/bxi/administracion/cuentasterceros/solicitudes/validacion`;
export const pathDesvincularSolicitud = `${URL}/cxf/bxi/administracion/cuentasterceros/desvincular`;

// ==> Notifications
export const pathGetNotifications = (codUser, page, perPage) =>
  `${URL}/cxf/bxi/notificacion/usuario/${codUser}/${page}/${perPage}`;
export const pathChangeStatusNotifications = `${URL}/cxf/bxi/notificacion/usuario/cambioEstado`;

// ==> Punto Express
export const pathServiciosPex = `${URL}/cxf/bxi/consultas/serviciosPEX`;
export const pathConsultaPex = `${URL}/cxf/bxi/puntoExpress/consulta/`;
export const pathPagoPex = `${URL}/cxf/bxi/puntoExpress/pagos/`;

//  ==> Sesióm
export const pathExtendSession = `${URL}/cxf/generar/token/BXI/refresh`;
export const pathObtenerDatosUsuario = `${URL}/cxf/bxi/datos/usuario/obtenerDatosUsuario`;
export const pathIniciarSession = `${URL}/cxf/bxi/inicioSesion`;
export const pathCerrarSession = `${URL}/cxf/bxi/cerrarSesion`;
export const pathGetImagenesUser = `${URL}/cxf/bxi/consultas/imagenes`;
export const pathCambioClaveUsuario = `${URL}/cxf/bxi/usuario/clave/cambioClaveUsuario/`;
export const pathOlvideClave = `${URL}/cxf/bxi/usuario/olvideClave/`;
export const pathValidarCodeOlvideClave = `${URL}/cxf/bxi/usuario/verifCodigo/`;

// ==> Normativas de Seguridad
export const pathSolicitarCodAcceso = `${URL}/cxf/v2/soporte/ingreso/canales/inicioIngreso`;
export const pathValidarToken = `${URL}/cxf/v2/soporte/ingreso/canales/validarToken`;
export const pathSolicitarToken = `${URL}/cxf/v2/soporte/ingreso/canales/solicitarToken`;
export const pathNoLoginIngreso = `${URL}/cxf/soporte/comprobacion/movil/inicioIngreso`;
export const pathNoLoginSolicitarCode = `${URL}/cxf/soporte/comprobacion/movil/solicitarToken`;
export const pathNoLoginValidarCode = `${URL}/cxf/soporte/comprobacion/movil/validarToken`;

// ==> Cargos Automaticos
export const pathGetCargosAutomaticos = (codUser) =>
  `${URL}/cxf/bxi/cargos/automaticos/consulta/${codUser}/BXI`;
export const pathCambioEstadoCargAutomacios = `${URL}/cxf/bxi/cargos/automaticos/cambiar/estado`;
export const pathAgregarCargoAutomatico = `${URL}/cxf/bxi/cargos/automaticos/ingreso/`;
export const pathModificarCargoAutomatico = `${URL}/cxf/bxi/cargos/automaticos/cambiar/fecha`;

// ==> Transfer365
export const pathTransfer365 = `${URL}/cxf/bxi/transferencias365/cuentas`;
export const pathTransfer365Masivo = `${URL}/cxf/bxi/pago/transfer365/masiva`;
export const pathTransferA = `${URL}/cxf/bxi/consultas/transferirA`;
export const pathCalcularComision365 = `${URL}/cxf/bxi/consultas/valorComision/transfer365`;
export const pathCalcularIva365 = `${URL}/cxf/bxi/consultas/valorIva/transfer365`;
export const pathObtLimite365 = `${URL}/cxf/bxi/consultas/limite365Masiva`;
export const pathBancosDestino365 = `${URL}/cxf/bxi/consultas/bancosDestino365`;


// ==> Transfer365 Cuentahabientes
export const pathTransfer365CtaHabientes = `${URL}/cxf/bxi/transferencias/365/cuentahabiente`;
export const pathTransfer365CtaHValidar = `${pathTransfer365CtaHabientes}/validar`;
export const pathCalComision365CtaH = `${URL}/cxf/bxi/consultas/comision/cuentahabiente`;
export const pathCalIva365CtaH = `${URL}/cxf/bxi/consultas/iva/cuentahabiente`;

// ==> Transfer365 Obligaciones BCR
export const pathTransfer365ObBCR = `${URL}/cxf/bxi/transferencias/365/obligaciones`;
export const pathTransfer365ObBCRValidar = `${pathTransfer365ObBCR}/validar`;
export const pathCalComision365ObBCR = `${URL}/cxf/bxi/consultas/comision/obligaciones`;
export const pathCalIva365ObBCR = `${URL}/cxf/bxi/consultas/iva/obligaciones`;

// ==> Transfer365 Business Tercero a tercero
export const pathTransfer365Business = `${URL}/cxf/bxi/transferencias/365/terceroaterceros`;
export const pathCalComision365Business = `${URL}/cxf/bxi/consultas/comision/tercero`;
export const pathCalIva365Business = `${URL}/cxf/bxi/consultas/iva/tercero`;

// ==> Transfer365 Móvil
export const pathTransfer365Movil = `${URL}/cxf/bxi/transferencias365movil/cuentas`;
export const pathCalComision365Movil = `${URL}/cxf/bxi/consultas/comision/movil`;
export const pathCalIva365Movil = `${URL}/cxf/bxi/consultas/iva/movil`;
export const pathBancosDestino365Movil = `${URL}/cxf/bxi/consultas/bancosDestinoT365Movil`;

// ==> Salud para Todos
export const pathPlanesSegurosSalud = `${URL}/cxf/bxi/consultas/planesPagosSeguroSalud`;
export const pathTiposPagosSeguros = `${URL}/cxf/bxi/consultas/tiposPagosSeguro`;
export const pathFormasPagosSeguros = `${URL}/cxf/bxi/consultas/formasPagosSeguro`;
export const pathSaludParaTodos = `${URL}/cxf/bxi/saludTodos`;
export const pathConsultasSaludParaTodos = `${URL}/cxf/bxi/consultaSaludTodos`;

// ==> Cambio de PIN de ATM
export const pathCambioPinATM = `${URL}/cxf/bxi/cambio/pinatm`;

// ==> Planillas procesadas
export const pathPlanillasProcesadas = `${URL}/cxf/bxi/consultaPlanillasProcesadas`;

// ===> Servicio Cache Translate
export const pathCacheTranslate = `${URL}/cxf/bxi/translate`;

// ==> Visualización de cheques
export const pathDownloadChequePDF = `${URL}/cxf/bxi/visualizarCheque`;

// ==> Sol. Actualización de datos
export const pathSolActualizacionDatos = `${URL}/cxf/bxi/solActualizacionDatos`;

// ==> Sol. Cuentas frecuentes 365 Individual y Movil
export const pathAgregarCuentasFrecuentes = `${URL}/cxf/bxi/cuentaFrecuente/solicitud/agregar`;

// ==> Cuentas frecuentes365 Consultar solicitud
export const pathConsultaSolicitudes = `${URL}/cxf/bxi/cuentaFrecuente/solicitud/consultar`;

// ==> Cuentas frecuentes365 Validar solicitudes
export const pathValidarSolicitud = `${URL}/cxf/bxi/cuentaFrecuente/solicitud/validar`;

// ==> Cuentas frecuentes365 Eliminar cuentas
export const pathEliminarCuentas = `${URL}/cxf/bxi/cuentaFrecuente/eliminar`;

// ==> Cuentas frecuentes365 Consultar cuentas
export const pathConsultarCuenta = `${URL}/cxf/bxi/cuentaFrecuente/consultar`;

// ==> Calcular comision SIPA
export const calcularComisionSipa = `${URL}/cxf/bxi/t365card/consultas/parametro/`;
